import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

document.addEventListener('DOMContentLoaded', () => {
  const pswp = document.querySelector('.pswp')
  const pswpLink = Array.from(document.querySelectorAll('.pswp-link'))
  const items = pswpLink.map((link) => {
    const img = link.querySelector('img')
    return {
      src: link.getAttribute('href'),
      w: parseInt(img.getAttribute('width')),
      h: parseInt(img.getAttribute('height')),
    }
  })
  pswpLink.forEach((link, i) => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      const gallery = new PhotoSwipe(pswp, PhotoSwipeUIDefault, items, {
        index: i
      })
      gallery.init()
    })
  })
})